<template>
    <div class="card p-3">
        <h3 class="font-bold text-lg text-heading">Pengajuan Skripsi</h3>
        <div class="w-full mt-2">
            <form @submit.prevent="submit()">
                <div class="p-7 space-y-5">
                    <div>
                        <label class="font-semibold">Judul Skripsi</label><br>
                        <input class="form-control" v-model="form.data.title" type="text" value="" autocomplete="off" required>
                    </div>
                    <div>
                        <label class="font-semibold">Tipe Skripsi</label><br>
                        <select v-model="form.data.type" class="form-control">
                            <option value="">Pilih tipe skripsi</option>
                            <option value="type-a">Tipe A</option>
                            <option value="type-b">Tipe B</option>
                        </select>
                    </div>
                    <div>
                        <div v-for="(i, index) in form.data.mentors" :key="index">
                            <div class="flex justify-between">
                                <label class="font-semibold">Pembimbing {{ index + 1 }}</label>
                                <a v-if="index > 0 || form.data.mentors.length > 1" href="" @click.prevent="removeMentor(index)">Remove</a>
                            </div>
                            <select @change="(e) => onMentorChange(e, index)" class="form-control" :class="{ 'mb-0': index === form.data.mentors.length - 1}">
                                <option value="">Pilih Pembimbing</option>
                                <option v-for="i in mentors" :key="i.id" :value="i.id">{{ i.fullname }}</option>
                            </select>
                        </div>
                        <a href="javascript:void(0)" @click="addMentor">Tambah Pembimbing</a>
                    </div>
                </div>
                <!-- form footer -->
                <div class="border-t flex justify-center border-gray-100 p-6">
                    <button class="lg:w-1/3 h-9 lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 px-5">
                        Ajukan Sekarang
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'PengajuanSkripsi',
        computed: {
            ...mapState('mentors', { mentors: 'data' }),
            ...mapState('skripsi', { data: 'data', loading: 'loading' })
        },
        data () {
            return {
                form: {
                    data: {
                        title: '',
                        type: '',
                        mentors: [0, 0]
                    },
                    loading: true
                }
            }
        },
        methods: {
            ...mapActions('mentors', { fetchMentors: 'fetch' }),
            ...mapActions('skripsi', { createSkripsi: 'create', fetchSkripsi: 'fetch' }),
            submit () {
                this.form.loading = true
                this.createSkripsi(this.form.data)
                    .then(() => {
                        this.$swal({
                            imageUrl: require('@/assets/images/skripsi-created.png'),
                            imageWidth: 400,
                            title: 'Pengajuan Skripsi',
                            text: 'Judul Skripsi anda sedang tahap approval, mohon menunggu untuk lanjut ketahap selanjutnya'
                        })
                        this.fetchSkripsi()
                            .then(() => {
                                this.$router.push({ name: 'InformasiSkripsi' })
                            })
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something Wrong'
                        })
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            onMentorChange (e, index) {
                this.form.data.mentors[index] = e.target.value
            },
            addMentor () {
                this.form.data.mentors.push(0)
            },
            removeMentor (index) {
                this.form.data.mentors.splice(index, 1)
            }
        },
        mounted () {
            this.fetchMentors()
            if (this.data.id) {
                this.$router.push({ name: 'PengajuanBimbingan' })
            }
        }
    }
</script>
